import {
  Row,
  Col,
  Card,
  InputGroup,
  FormControl,
  Table,
  Nav,
  Modal,
  Button,
} from "react-bootstrap";
import { topicService } from "../../services/topicService";
import { useEffect, useState } from "react";
import preloader from "./../../assets/images/CC-Loaderfinal.gif";
import moment from "moment";
import TopicEditor from "../TopicEditor/TopicEditor";
import { Upload } from "filestack-js/build/main/lib/api/upload";

export default function Exam() {
  const [topicList, setTopicList] = useState([]);
  const [selectedEditList, setSelectedEditList] = useState({});
  const [deletedTopic, setDeletedTopic]: any = useState({});
  const [deletedTopicIdx, setDeletedTopicIdx]: any = useState({});
  const [viewState, setViewState] = useState("View");
  const [searchParam] = useState(["name"]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const handleClose = () => setShowDelete(false);
  async function getExamData() {
    try {
      setIsLoading(true);
      let response: any = await topicService.getAllTopics();
      if (response.data) {
        let filterCoaches = response.data.available_topics.filter(
          (res: any) => res.type == "assessment"
        );
        setTopicList(filterCoaches);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    } catch (eror) {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    getExamData();
  }, []);
  const refreshState = () => {
    setViewState("View");
    getExamData();
  };
  const searchTopic = () => {
    return topicList.filter((item: any) => {
      console.log(item);
      return searchParam.some((newItem: any) => {
        return (
          item[newItem]
            .toString()
            .toLowerCase()
            .indexOf(searchQuery.toLowerCase()) > -1
        );
      });
    });
  };

  const deleteExam = async () => {
    try {
      let response: any = await topicService.deleteTopic(deletedTopic._id);
      topicList.splice(deletedTopicIdx, 1);
      setTopicList(topicList);
      setShowDelete(false);
    } catch (eror) {}
  };

  return (
    <section className="pcoded-main-container">
      <div className="pcoded-wrapper">
        <div className="pcoded-content">
          <div className="page-header-title">
            <h5 className="m-b-10" style={{ fontSize: "30px" }}></h5>
          </div>
          <div className="pcoded-inner-content">
            <Row>
              <Col>
                <Card>
                  <Card.Header className="d-flex  justify-content-between align-items-center">
                    <Card.Title as="h5" style={{ padding: "0px" }}>
                      Exams{" "}
                    </Card.Title>
                    <Nav variant="tabs" defaultActiveKey="#first">
                      {isloading && (
                        <div className="preloader">
                          <img src={preloader} alt="preloder" />
                        </div>
                      )}
                      {viewState == "View" && (
                        <a
                          className="btn-outline"
                          onClick={() => {
                            setSelectedEditList({
                              refreshState,

                              status: true,
                              name: "",
                              description: "",
                              imageUrl: "",
                              type: "assessment",
                              sub_topics: [
                                {
                                  name: "",
                                  description: "",
                                  imageUrl: "",
                                },
                              ],
                            });
                            setViewState("Upload");
                          }}
                        >
                          {" "}
                          Create New Exam{" "}
                        </a>
                      )}
                      {viewState == "Upload" && (
                        <a
                          className="btn-outline"
                          onClick={() => {
                            getExamData();
                            setViewState("View");
                          }}
                        >
                          {" "}
                          Back{" "}
                        </a>
                      )}
                    </Nav>
                  </Card.Header>
                  <Card.Body>
                    {viewState == "View" && (
                      <div>
                        {
                          <InputGroup className="mb-3">
                            <FormControl
                              placeholder="Search Exam"
                              aria-label="Search Exam"
                              aria-describedby="basic-addon2"
                              onChange={(e) => setSearchQuery(e.target.value)}
                            />
                          </InputGroup>
                        }
                        <Table striped responsive>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Read Count</th>
                              <th>SubTopics</th>
                              <th>Status</th>
                              <th>CreatedAt</th>
                              <th>UpdatedAt</th>
                              <th className="text-center">Edit</th>
                              <th className="text-center">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {topicList.length > 0 &&
                              searchTopic().map((topic: any, index: any) => {
                                return (
                                  <tr>
                                    <td>{topic.name}</td>
                                    <td>{topic.readCount}</td>
                                    <td>{topic.sub_topics?.length}</td>
                                    <td>{topic.status}</td>
                                    <td>{moment(topic.createdAt).fromNow()}</td>
                                    <td>{moment(topic.updatedAt).fromNow()}</td>
                                    <td
                                      className="text-center tcolor"
                                      onClick={() => {
                                        setSelectedEditList({
                                          ...topic,
                                          status: false,
                                          refreshState,
                                        });
                                        setViewState("Upload");
                                      }}
                                    >
                                      <i className="feather icon-edit cursor_pointer"></i>
                                    </td>
                                    <td
                                      className="text-center tcolor"
                                      onClick={() => {
                                        setDeletedTopic(topic);
                                        setDeletedTopicIdx(index);
                                        setShowDelete(true);
                                      }}
                                    >
                                      <i className="feather icon-trash-2 cursor_pointer"></i>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      </div>
                    )}
                    {viewState == "Upload" && (
                      <TopicEditor {...selectedEditList} />
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Modal
        show={showDelete}
        onHide={handleClose}
        animation={true}
        style={{ margin: "0px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure want to delete? </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteExam}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}
//   {!showsubTopic &&
//     topicList.map((topic: any) => {
//       return (
//         <tr>
//           <td>{topic.name}</td>
//           <td>
//             <img
//               height="20px"
//               src={topic.imageUrl}
//             ></img>
//           </td>
//           <td>{topic.readCount}</td>
//           <td

//           >
//             view (Subtopics:{" "}
//             {topic?.sub_topics?.length})
//           </td>
//           <td>{topic.status}</td>
//           <td>

//           </td>
//           <td className="text-center tcolor"

//           >
//             <i className="feather icon-edit"></i>
//           </td>
//           <td className="text-center tcolor"><i className="feather icon-trash-2"></i></td>
//         </tr>
//       );
//     })}
