import { AuthService } from "../services/AuthService"
import { Navigate, useNavigate } from "react-router-dom"
export default function AuthMainGuard(props: any) {
    if (AuthService.userLoggerIn) {
        return <>
            {props.children}
        </>
    }
    return <Navigate to="/login" replace />;

}