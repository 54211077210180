import { useEffect, useState } from "react";
import "./UserDetails.css";
import { AuthService } from "../../services/AuthService";
import preloader from "./../../assets/images/CC-Loaderfinal.gif";
import moment from "moment";
import {
  Row,
  Col,
  Table,
  Card,
  Button,
  InputGroup,
  FormControl,
  Modal
} from "react-bootstrap";
import { useParams } from "react-router-dom";




export default function UserDetails() {
  const [userLoader, setUserLoader] = useState(false);
  const { userId } = useParams();
  const [userDetails, setUserDetails]: any = useState([]);
  const [userSessions, setUserSessions] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [sessionToBeDeleted, setSessionToBeDeleted]: any = useState({});
  const handleClose = () => setShowDelete(false);

  async function getUserDetails() {
    try {
      setUserLoader(true);
      let response: any = await AuthService.getUserById(userId);
      if (response.data.userDetails) {
        setUserSessions(response.data.userDetails.chatSessions);
        setUserDetails(response.data.userDetails);
      }
      setUserLoader(false);
    } catch (eror) {
      setUserLoader(true);
    }
  }

  useEffect(() => {
    getUserDetails();
  }, []);
  const [searchParam] = useState(["topic", "subtopic"]);
  const [searchQuery, setSearchQuery] = useState("");

  const searchSession = () => {
    return userSessions.filter((item: any) => {

      return searchParam.some((newItem: string) => {
        const value = item[newItem];

        if (Array.isArray(value)) {
          return value.some((subItem: string) =>
            subItem.toString().toLowerCase().includes(searchQuery.toLowerCase())
          );
        } else if (typeof value === "string") {
          return value.toLowerCase().includes(searchQuery.toLowerCase());
        } else {
          return false;
        }
      });
    });
  };

  const deleteSession = async () => {
    try {
      setUserLoader(true);
      setShowDelete(false);
      let response: any = await AuthService.deleteUserChatSession(sessionToBeDeleted,userDetails._id);
      setSessionToBeDeleted({});
      getUserDetails();
      setUserLoader(true);
    } catch (eror) { 
      setUserLoader(false);
    }
  };



  return (
    <section className="pcoded-main-container">
      <div className="pcoded-wrapper">
        <div className="pcoded-content">
          <div className="page-header-title">
            <h5 className="m-b-10" style={{ fontSize: "30px" }}></h5>
          </div>
          <div className="pcoded-inner-content">
            {userLoader && (
              <div className="preloader">
                <img src={preloader} alt="preloder" />
              </div>
            )}
            <Row>
              <Col>
                <Card>
                  <Card.Header>
                    <Card.Title as="h5">Users Details</Card.Title>
                    <div>
                      <br />
                      <h6 className="text-capitalize" >User Name: {userDetails?.name}</h6>
                      <p >Email: {userDetails?.email}</p>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    {
                      <InputGroup className="mb-3">
                        <FormControl
                          placeholder="Search Session"
                          aria-label="Search User"
                          aria-describedby="basic-addon2"
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </InputGroup>
                    }
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th>Topic</th>
                          <th>Sub Topic</th>
                          <th>Chat Type</th>
                          <th>Level</th>
                          <th className="text-center">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userSessions.length > 0 &&
                          searchSession().map((session: any) => {
                            return (
                              <tr >
                                <td className="text-capitalize">
                                  {session.topic}
                                </td>
                                <td className="text-capitalize" >{session.subtopic}</td>
                                <td className="text-capitalize" >{session.chatType}</td>
                                <td className="text-capitalize" >{session.level}</td>
                                <td
                                  className="text-center tcolor"
                                  onClick={() => {
                                    setSessionToBeDeleted(session);
                                    setShowDelete(true);
                                  }}
                                >
                                  <i className="feather icon-trash-2 cursor_pointer"></i>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Modal
        show={showDelete}
        onHide={handleClose}
        animation={true}
        style={{ margin: "0px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure want to delete? </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteSession}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}
