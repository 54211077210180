import { axiosPost, axiosGet } from "./HttpBaseService";

export const topicService = {
  createTopic: (body: any) => {
    return axiosPost(`${process.env.REACT_APP_API}/admin/createTopic`, body);
  },
  createEmbedding: (body: any, uploadtype: any, embeddingType: any) => {
    console.log("embedding is" + `${process.env.REACT_APP_CORNER_API}/admin/generateEmbedding/` + embeddingType + "/" + uploadtype)
    return axiosPost(
      `${process.env.REACT_APP_CORNER_API}/admin/generateEmbedding/` + embeddingType + "/" + uploadtype,
      body
    );
  },
  updateTopic: (body: any) => {
    return axiosPost(`${process.env.REACT_APP_API}/admin/updateTopic`, body);
  },
  deleteTopic: (topicId: any) => {
    return axiosGet(`${process.env.REACT_APP_API}/admin/deleteTopic/${topicId}`);
  },
  updateSubTopic: (body: any) => {
    return axiosPost(`${process.env.REACT_APP_API}/admin/updateSubTopic`, body);
  },
  coachTopics: () => {
    return axiosGet(`${process.env.REACT_APP_CORNER_API}/admin/coachTopics`);
  },
  coachById: (id: any) => {
    return axiosGet(`${process.env.REACT_APP_CORNER_API}/admin/getCoachTopic/` + id);
  },
  QAResponse: (body: any) => {
    return axiosPost(`${process.env.REACT_APP_CORNER_API}/admin/queryChatResponse`, body);
  },
  getAllTopics: () => {
    return axiosGet(`${process.env.REACT_APP_API}/chat/getTopics`)
  }
};
