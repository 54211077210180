import { useEffect, useState } from "react";
import {
  Form,
  Button,
  Card,
  Nav,
  Badge,
  ProgressBar,
  ListGroup,
  Container,
  Row,
  Col,
  Dropdown,
  InputGroup,
  Table,
  FormControl,
  Modal,
} from "react-bootstrap";
import "./coach.css";
import moment from "moment";
import { topicService } from "../../services/topicService";
import preloader from "./../../assets/images/CC-Loaderfinal.gif";
import * as filestack from "filestack-js";
const client = filestack.init("Am6LcC4IkQgy8iFWpZnFLz");
export default function CoachName() {
  async function coachNameData() {
    try {
      setIsLoading(true);
      let response: any = await topicService.getAllTopics();
      if (response.data) {
        let filterCoaches = response.data.available_topics.filter(
          (res: any) => res.type == "coach"
        );
        setTopicList(filterCoaches);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    } catch (eror) {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    coachNameData();
  }, []);
  const deleteCoach = async () => {
    try {
      let response: any = await topicService.deleteTopic(deletedTopic._id);
      topicList.splice(deletedTopicIdx, 1);
      setTopicList(topicList);
      setShowDelete(false);
    } catch (eror) { }
  };
  const now = 60;
  const [isloading, setIsLoading] = useState(false);
  const [topicList, setTopicList] = useState([{}]);
  const [coachId, setCoachId] = useState("");
  const [viewState, setViewState] = useState("View");
  const [uploadState, setUploadState] = useState(false);
  const [fileData, setFileData]: any = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [color, setcolor] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [uploadType, setUploadType] = useState("pdf");
  const [indexCreated, setIndexCreated] = useState(false);
  const [chatMessages, setChatMessages]: any = useState([]);
  const [selectedOption, setSelectedTopic]: any = useState();
  const [deletedTopic, setDeletedTopic]: any = useState({});
  const [deletedTopicIdx, setDeletedTopicIdx]: any = useState({});
  const [isNewTopic, setIsNewTopic] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const handleClose = () => setShowDelete(false);
  const [indexDetails, setindexDetails] = useState({
    name: "",
    description: "",
    vectorId: "",
    namespace: "",
  });
  const [coachTyping, setCoachTyping] = useState(false);
  const [options, setOptions] = useState([]);
  const [uploadStatus, setUploadStatus] = useState(false);
  const handleTopicChange = async (selectedTopic: any) => {
    console.log(selectedOption);

    setName(selectedTopic.name);
    setImageUrl(selectedTopic.imageUrl);
    setcolor(selectedTopic.color);
    setDescription(selectedTopic.description);
  };
  const handleFileUpload = (event: any) => {
    // do something with event data
    console.log(event.target.files[0]);
    setFileData(event.target.files[0]);
  };
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      setUploadStatus(true);
      try {
        const result = await client.upload(file);
        console.log("File uploaded:", result.url);
        // Process the uploaded file here

        setImageUrl(result.url);
        setUploadStatus(false);
      } catch (error) {
        console.error("Error uploading file:", error);
        setUploadStatus(false);
      }
    }
  };
  const submitFile = async () => {
    // do something with event data
    setUploadState(true);
    console.log(fileData, uploadState);
    const formData = new FormData();
    formData.append("type", "coach");
    if (fileData) {
      formData.append("file", fileData);
    }
    formData.append("color", color);
    formData.append("description", description);
    formData.append("imageUrl", imageUrl);
    formData.append("name", name);
    formData.append("prompt", "");
    console.log(formData.values());
    let embeddingType = "new";
    if (coachId == "" && fileData?.name) {
      embeddingType = "new";
    } else if (coachId != "" && fileData?.name) {
      embeddingType = "existing";
      formData.append("coachId", coachId);
    }
    var response: any = await topicService.createEmbedding(
      formData,
      uploadType,
      embeddingType
    );
    // }
    console.log(response);
    setTimeout(() => {
      if (response) {
        response = response.data;
        setindexDetails({
          name: response.embedding.topic["name"],
          description: response.embedding.topic["description"],
          vectorId: response.embedding.topic["vectorIndex"],
          namespace: response.embedding.topic["namespace"],
        });
      }
      setIndexCreated(true);
      setTimeout(() => {
        coachNameData();
        setViewState("View");
        setIndexCreated(false);
      }, 300);
    }, 1000);

    // setUploadState(false);
    console.log(fileData);
  };
  const [answer, setAnswer] = useState("");
  const [query, setQuery] = useState("");
  const [url, setUrl] = useState("");

  const QAAnswer = async () => {
    setCoachTyping(true);
    setChatMessages((prevMessages: any) => [
      ...prevMessages,
      { role: "user", content: query },
    ]);
    let response = await topicService.QAResponse({
      indexId: "random-vec-379629305",
      query,
      coachId: selectedOption,
    });
    console.log(response);
    setAnswer(response.data.response.response.text);
    setChatMessages((prevMessages: any) => [
      ...prevMessages,
      {
        role: "assistant",
        content: response.data.response.response.text,
      },
    ]);
    setCoachTyping(false);
    console.log(answer);
  };
  return (
    <section className="pcoded-main-container">
      <div className="pcoded-wrapper">
        <div className="pcoded-content">
          <div className="page-header-title">
            <h5 className="m-b-10" style={{ fontSize: "30px" }}></h5>
          </div>
          <div className="pcoded-inner-content">
            <Card>
              <Card.Header className="d-flex  justify-content-between align-items-center">
                <Card.Title as="h5" style={{ padding: "0px" }}>
                  Coaches{" "}
                </Card.Title>
                <Nav variant="tabs" defaultActiveKey="#first">
                  {/* <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        setViewState("Upload");
                      }}
                    >
                      Upload
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        setViewState("View");
                        coachNameData();
                        console.log(viewState);
                      }}
                    >
                      View
                    </Nav.Link>
                  </Nav.Item> */}
                  {viewState == "View" && (
                    <a
                      className="btn-outline"
                      onClick={() => {
                        setViewState("Upload");
                      }}
                    >
                      {" "}
                      Create New Coach{" "}
                    </a>
                  )}
                  {viewState == "Upload" && (
                    <a
                      className="btn-outline"
                      onClick={() => {
                        coachNameData();
                        setViewState("View");
                      }}
                    >
                      {" "}
                      Back{" "}
                    </a>
                  )}
                </Nav>
              </Card.Header>
              <Card.Body>
                {isloading && (
                  <div className="preloader">
                    <img src={preloader} alt="preloder" />
                  </div>
                )}

                {viewState == "Upload" && uploadState && (
                  <div className="overlay">
                    {indexCreated ? (
                      <div className="loading-container">
                        <h6>Index created successfully!</h6>
                        {indexDetails && (
                          <div>
                            <h4>Name: {indexDetails.name}</h4>
                            <h4>Description: {indexDetails.description}</h4>
                            <h4>Vector ID: {indexDetails.vectorId}</h4>
                            <h4>Namespace: {indexDetails.namespace}</h4>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="loading-container">
                        <h4>Stay tuned, creating the vector index...</h4>
                        <div className="loader"></div>
                      </div>
                    )}
                  </div>
                )}
                {viewState == "Upload" && (
                  <Form>
                    {/* <Form.Group
                      controlId="topicOption"
                      style={{ marginBottom: "10px" }}
                    >
                      <Form.Check
                        inline
                        type="radio"
                        label="New"
                        value="new"
                        checked={isNewTopic}
                        onChange={handleOptionChange}
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Existing"
                        value="existing"
                        checked={!isNewTopic}
                        onChange={handleOptionChange}
                      />
                    </Form.Group> */}

                    {!isNewTopic && (
                      <Form.Group
                        controlId="topic"
                        style={{ marginBottom: "10px", width: "400px" }}
                      >
                        <Form.Label>Select a Topic</Form.Label>
                        <Form.Select
                          value={selectedOption}
                          onChange={(e) => handleTopicChange(e.target.value)}
                        >
                          <option value="">Select</option>
                          {options.map((option: any) => (
                            <option key={option._id} value={option._id}>
                              {option.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    )}

                    <Form.Group
                      controlId="coachName"
                      style={{ marginBottom: "10px", width: "700px" }}
                    >
                      <Form.Label>Coach Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter coach name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group
                      controlId="fileUpload"
                      style={{ width: "700px", marginBottom: "10px" }}
                    >
                      <Form.Label>Image</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={(event: any) => {
                          handleImageUpload(event);
                        }}
                        size="lg"
                        style={{
                          width: "600px",
                          margin: "20px 0px",
                        }}
                      />
                      {uploadStatus && <span>Uploading...</span>}
                      {/* {imageUrl} */}
                      <div className="up-img "><img src={imageUrl} /></div>
                    </Form.Group>

                    <Form.Group
                      controlId="coachDescription"
                      style={{ marginBottom: "10px", width: "700px" }}
                    >
                      <Form.Label>Coach Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter coach description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="uploadType"
                      style={{ width: "400px", marginBottom: "10px" }}
                    >
                      <Form.Label>Upload Type</Form.Label>
                      <Form.Select
                        value={uploadType}
                        onChange={(e) => setUploadType(e.target.value)}
                      >
                        <option value="pdf">Pdf</option>
                        <option value="text">Txt</option>
                        <option value="youtube">Youtube</option>
                        <option value="podcast">Podcast</option>
                      </Form.Select>
                    </Form.Group>

                    {(uploadType === "youtube" || uploadType === "podcast") && (
                      <Form.Group
                        controlId="url"
                        style={{ paddingTop: "5px", width: "700px" }}
                      >
                        <Form.Label>Url:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Url"
                          value={url}
                          onChange={(event) => {
                            setUrl(event.target.value);
                          }}
                        />
                      </Form.Group>
                    )}

                    {(uploadType === "pdf" || uploadType === "text") && (
                      <Form.Group
                        controlId="fileUpload"
                        style={{ width: "700px", marginBottom: "10px" }}
                      >
                        <Form.Label>
                          Upload File For LLM ChatBot (* Please note that only
                          PDF or TXT file format is accepted for uploads.)
                        </Form.Label>
                        <Form.Control type="file" onChange={handleFileUpload} />
                      </Form.Group>
                    )}

                    <Button
                      variant="primary"
                      type="button"
                      onClick={submitFile}
                    >
                      Create new coach
                    </Button>
                  </Form>
                )}

                {viewState == "View" && (
                  <Card.Body>
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Image Url</th>
                          <th>Read Count</th>
                          <th>Status</th>
                          <th>createdAt</th>
                          <th>updatedAt</th>
                          <th className="text-center">Edit</th>
                          <th className="text-center">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {users.map((user: any) => { */}

                        {topicList.map((topic: any, index: any) => {
                          return (
                            <tr>
                              <td className="text-capitalize">{topic.name}</td>
                              <td>
                                <div className="coach-img">
                                  <img height="40px" src={topic.imageUrl}></img>
                                </div>
                              </td>
                              <td>{topic.readCount}</td>

                              <td>{topic.status}</td>
                              <td>{moment(topic.createdAt).fromNow()}</td>
                              <td>{moment(topic.updatedAt).fromNow()}</td>
                              <td
                                className="text-center tcolor"
                                onClick={() => {
                                  setViewState("Upload");
                                  setCoachId(topic._id);
                                  handleTopicChange(topic);
                                }}
                              >
                                <i className="feather icon-edit cursor_pointer"></i>
                              </td>
                              <td
                                className="text-center tcolor"
                                onClick={() => {
                                  setDeletedTopic(topic);
                                  setDeletedTopicIdx(index);
                                  setShowDelete(true);
                                }}
                              >
                                <i className="feather icon-trash-2 cursor_pointer"></i>
                              </td>
                            </tr>
                          );
                        })}

                        {/* <td>{user.name}</td>
                                <td>{user.email}</td>
                                <td>{String(user.isEmailVerified)}</td>
                                <td>{String(!user.hasPasword)}</td>
                                <td>{moment(user.createdAt).fromNow()}</td>
                                <td>{moment(user.updatedAt).fromNow()}</td> */}

                        {/* })} */}
                      </tbody>
                    </Table>
                  </Card.Body>
                )}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <Modal
        show={showDelete}
        onHide={handleClose}
        animation={true}
        style={{ margin: "0px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Are you sure want to delete? </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteCoach}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}
