import { AuthService } from "../services/AuthService";
import { Navigate, useNavigate } from "react-router-dom";
export default function AuthGuard(props: any) {
  console.log(AuthService.userLoggerIn);
  if (AuthService.userLoggerIn) {
    return <Navigate to="/user" replace />;
  }
  return (
    <>
      <div>{props.children}</div>
    </>
  );
}
