import { useEffect, useState } from "react";
import {
  Form,
  Button,
  Card,
  Nav,
  Accordion,
  Row,
  Col,
  InputGroup,
  FormControl,
  Table,
  Modal,
} from "react-bootstrap";
import { topicService } from "../../services/topicService";
import moment from "moment";
import preloader from "./../../assets/images/CC-Loaderfinal.gif";
import * as filestack from "filestack-js";
import TopicEditor from "../TopicEditor/TopicEditor";
const client = filestack.init("Am6LcC4IkQgy8iFWpZnFLz");

export default function Topic() {
  const [viewState, setViewState] = useState("View");
  const [selectedEditList, setSelectedEditList] = useState({});
  const [name, setName] = useState("");
  const [deletedTopic, setDeletedTopic]: any = useState({});
  const [deletedTopicIdx, setDeletedTopicIdx]: any = useState({});
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [searchParam] = useState(["name"]);
  const [searchQuery, setSearchQuery] = useState("");
  const [subtopicList, setSubTopicList] = useState([
    {
      name: "",
      description: "",
      imageUrl: "",
    },
  ]);

  const [editList, setEditList]: any = useState([]);
  const [activeTopicList, setActiveTopicList]: any = useState([]);
  const [showsubTopic, setShowsubTopic] = useState(false);
  const [topicList, setTopicList] = useState([]);
  async function updateTopic() {
    try {
      setIsLoading(true);
      let finalTopic = {
        _id: editList._id,
        name,
        description,
        imageUrl,
      };
      let response: any;
      if (!showsubTopic) {
        response = await topicService.updateTopic(finalTopic);
      } else {
        response = await topicService.updateSubTopic(finalTopic);
      }
      if (response.data) {
        setTopicList(response.data.available_topics);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    } catch (eror) {
      setIsLoading(false);
    }
  }
  const searchTopic = () => {
    return topicList.filter((item: any) => {
      console.log(item);
      return searchParam.some((newItem: any) => {
        return (
          item[newItem]
            .toString()
            .toLowerCase()
            .indexOf(searchQuery.toLowerCase()) > -1
        );
      });
    });
  };

  async function getTopicList() {
    try {
      setIsLoading(true);
      let response: any = await topicService.getAllTopics();
      if (response.data) {
        let filterCoaches = response.data.available_topics.filter(
          (res: any) => res.type == "topic"
        );
        setTopicList(filterCoaches);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    } catch (eror) {
      setIsLoading(false);
    }
  }
  const refreshState = () => {
    setViewState("View");
    getTopicList();
  };
  useEffect(() => {
    getTopicList();
  }, []);
  const addSubtopic = () => {
    setSubTopicList([
      ...subtopicList,
      {
        name: "",
        description: "",
        imageUrl: "",
      },
    ]);
    console.log(subtopicList);
  };
  const removeSubtopic = (index: any) => {
    let subList = [...subtopicList];
    subList.splice(index, 1);
    setSubTopicList(subList);
  };
  const onChangeSubtopic = (index: any, attrname: any, event: any) => {
    let subList: any = [...subtopicList];
    subList[index][attrname] = event.target.value;
    setSubTopicList(subList);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (updatedData: any) => {
    setShow(true);
    setName(updatedData.name);
    setDescription(updatedData.description);
    setImageUrl(updatedData.imageUrl);
    setEditList(updatedData);
  };
  const deleteTopic = async () => {
    try {
      let response: any = await topicService.deleteTopic(deletedTopic._id);
      topicList.splice(deletedTopicIdx, 1);
      setTopicList(topicList);
      setShow(false);
    } catch (eror) {}
  };
  const submitHandler = async () => {
    console.log(subtopicList);
    let finalTopic = {
      name,
      description,
      imageUrl,
      sub_topics: subtopicList,
      typeId: "new",
    };
    let response = await topicService.createTopic(finalTopic);
  };

  const [updateindex, setUpdateIndex] = useState();
  // const
  const [uploadStatus, setUploadStatus] = useState(false);
  const [uploadSubTopicStatus, setUploadSubTopicStatus] = useState(false);
  const [topicImage, setTopicImage] = useState("");
  const [subTopicImage, setSubTopicImage] = useState("");
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    if (value === "topic") {
      setUploadStatus(true);
    } else {
      setUploadSubTopicStatus(true);
    }
    const file = event.target.files?.[0];

    if (file) {
      try {
        const result = await client.upload(file);
        console.log("File uploaded:", result.url);
        // Process the uploaded file here
        if (value == "topic") {
          setTopicImage(result.url);
          setUploadStatus(false);
        } else {
          setSubTopicImage(result.url);
          setUploadSubTopicStatus(false);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };
  return (
    <section className="pcoded-main-container">
      <div className="pcoded-wrapper">
        <div className="pcoded-content">
          <div className="page-header-title">
            <h5 className="m-b-10" style={{ fontSize: "30px" }}></h5>
          </div>
          <div className="pcoded-inner-content">
            {isloading && (
              <div className="preloader">
                <img src={preloader} alt="preloder" />
              </div>
            )}

            <Row>
              <Col>
                <Card>
                  <Card.Header className="d-flex  justify-content-between align-items-center">
                    <Card.Title as="h5" style={{ padding: "0px" }}>
                      {showsubTopic
                        ? `SubTopic List [ Topic name: ${activeTopicList.name} ] `
                        : "Topic List"}
                    </Card.Title>
                    <Nav variant="tabs" defaultActiveKey="#first">
                      {isloading && (
                        <div className="preloader">
                          <img src={preloader} alt="preloder" />
                        </div>
                      )}
                      {viewState == "View" && (
                        <a
                          className="btn-outline"
                          onClick={() => {
                            setSelectedEditList({
                              refreshState,
                              formStatus: true,
                              status: 'active',
                              name: "",
                              description: "",
                              imageUrl: "",
                              type: "topic",
                              sub_topics: [
                                {
                                  name: "",
                                  description: "",
                                  imageUrl: "",
                                },
                              ],
                            });
                            setViewState("Upload");
                          }}
                        >
                          {" "}
                          Create New Topic{" "}
                        </a>
                      )}
                      {viewState == "Upload" && (
                        <a
                          className="btn-outline"
                          onClick={() => {
                            getTopicList();
                            setViewState("View");
                          }}
                        >
                          {" "}
                          Back{" "}
                        </a>
                      )}
                    </Nav>
                  </Card.Header>
                  <Card.Body>
                    {viewState == "Upload" && (
                      <TopicEditor {...selectedEditList} />
                    )}
                    {viewState == "View" && (
                      <div>
                        {
                          <InputGroup className="mb-3">
                            <FormControl
                              placeholder="Search Topic"
                              aria-label="Search Topic"
                              aria-describedby="basic-addon2"
                              onChange={(e) => setSearchQuery(e.target.value)}
                            />
                          </InputGroup>
                        }
                        <Table striped responsive>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Image Url</th>
                              {!showsubTopic && <th>Read Count</th>}
                              {!showsubTopic && <th> SubTopics</th>}
                              <th>status</th>
                              {!showsubTopic && <th>updatedAt</th>}
                              <th className="text-center">Edit</th>
                              <th className="text-center">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!showsubTopic &&
                              topicList.length > 0 &&
                              searchTopic().map((topic: any, index: any) => {
                                return (
                                  <tr>
                                    <td>{topic.name}</td>
                                    <td>
                                      <img
                                        height="20px"
                                        src={topic.imageUrl}
                                      ></img>
                                    </td>
                                    <td>{topic.readCount}</td>
                                    <td
                                      onClick={() => {
                                        // setShowsubTopic(true);
                                        setActiveTopicList(topic);
                                      }}
                                    >
                                      {topic?.sub_topics?.length}
                                    </td>
                                    <td>{topic.status}</td>
                                    <td>{moment(topic.updatedAt).fromNow()}</td>
                                    <td
                                      className="text-center tcolor"
                                      onClick={() => {
                                        setSelectedEditList({
                                          ...topic,
                                          formStatus: false,
                                          refreshState,
                                        });
                                        setViewState("Upload");
                                      }}
                                    >
                                      <i className="feather icon-edit cursor_pointer"></i>
                                    </td>
                                    <td
                                      className="text-center tcolor"
                                      onClick={() => {
                                        setDeletedTopic(topic);
                                        setDeletedTopicIdx(index);
                                        setShow(true);
                                      }}
                                    >
                                      <i className="feather icon-trash-2 cursor_pointer"></i>
                                    </td>
                                  </tr>
                                );
                              })}
                            {showsubTopic &&
                              activeTopicList.sub_topics.map((topic: any) => {
                                return (
                                  <tr>
                                    <td>{topic.name}</td>
                                    <td>
                                      <img
                                        height="20px"
                                        src={topic.imageUrl}
                                      ></img>
                                    </td>
                                    {/* <td>{topic.readCount}</td> */}
                                    {/* <td onClick={handleShow}>view ({})</td> */}
                                    <td>{topic.status}</td>
                                    {/* <td>{topic.updatedAt}</td> */}
                                    <td
                                      className="text-center tcolor"
                                      onClick={() => {
                                        handleShow(topic);
                                      }}
                                    >
                                      <i className="feather icon-edit cursor_pointer"></i>
                                    </td>
                                    <td className="text-center tcolor">
                                      <i className="feather icon-trash-2 cursor_pointer"></i>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* <Button variant="primary" onClick={handleShow}>
              Launch demo modal
            </Button> */}

            <Modal
              show={show}
              onHide={handleClose}
              animation={true}
              style={{ margin: "0px" }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Delete</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>Are you sure want to delete? </div>
              </Modal.Body>
              <Modal.Footer>
                <Button   variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={deleteTopic}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </section>
  );
}
