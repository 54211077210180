import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthService } from "../services/AuthService";
import { axiosPost } from "../services/HttpBaseService";
import "./login.css";
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const userlogin: any = async () => {
    try {
      const response: any = await AuthService.Login({ email, password });
      if (response.data.user && response.data.user.is_admin) {
        AuthService.userLoggerIn = true;
        navigate("/user");
      }
    } catch (error) {}
  };
  return (
    <>
       <div className="login-section">
          <div className="container">
              <div className="loginForm_component__main">
                  <img  src="https://app.coachchat.me/assets/images/login-bg.svg" alt="CoachChat" className="lf__img" />
                  <div className="lfc__form box-shadow br-20">
                    
                     <h1 className="form__heading">Welcome <br/>coachchat Admin</h1>
                    <p className="form__heading__txt">Hey, Enter your details to get sign in to <br/> your account</p>
                    <div className="form__outer">
                        <form>
                                  <div className="input-group mb-3">
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Email"
                                      onChange={(event) => {
                                        setEmail(event.target.value);
                                      }}
                                    />
                                  </div>
                                  <div className="input-group mb-4">
                                    <input
                                      type="password"
                                      className="form-control"
                                      placeholder="Password"
                                      onChange={(event) => {
                                        setPassword(event.target.value);
                                      }}
                                    />
                                  </div>
                                  <div className="input-group mb-4 text-center">
                                  <button
                                    className="btn w-100"
                                    type="button"
                                    onClick={userlogin}
                                  >
                                    Login
                                  </button>
                                  </div>
                                </form>
                    </div>
                    </div>
              </div>
          </div>
      </div>
    </>
  );
}
export default Login;
