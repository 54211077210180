import axios from "axios";

const config = {
  timeout: 4500,
};
export const axiosGet = (url: string) => {
  return axios.get(`${url}`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const axiosPost = (url: string, data: any) => {
  return axios.post(`${url}`, data, {
    withCredentials: true,
    headers: {},
  });
};

export const axiosDelete = (url: string, data: any = {}) => {
  return axios.delete(`${url}`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axiosPut = (url: string, data: any = {}) => {
  return axios.put(`${url}`, data, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const axiosPatch = (url: string, data: any = {}) => {
  return axios.patch(`${process.env.REACT_APP_API}${url}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
